<template>
  <div class="login-container">
    <div :class="`container-login100 ${!isMobile ? 'bg-slide' : ''}`">
      <span class="mask bg-gradient-primary opacity-6"></span>
      <div class="wrap-login100">
        <div class="login100-pic">
          <!-- <img :src="'/img/theme/card-p2.jpeg'" alt="IMG" /> -->
          <div class="login-text-area">
            <p class="text1">AB PLUS</p>
            <p class="text2">Your own account for</p>
            <p class="text3">LIFE ABROAD</p>
            <p class="text4">LIFESTYLE NEEDS</p>
            <p class="text5">Sign up for free on your phone</p>
            <div class="grid-qr">
              <div>
                <img width="180" src="/img/ab-qrcode.png" alt="" />
              </div>
              <div style="vertical-align: top; padding: 10px">
                <a
                  class="ls-layer-link"
                  href="https://itunes.apple.com/us/app/abfx/id1352795706?mt=8"
                  target="_blank"
                >
                  <img width="130" src="/img/apple.png" alt="" />
                </a>
                <br />
                <a
                  class="ls-layer-link"
                  href="https://play.google.com/store/apps/details?id=com.ab_money"
                  target="_blank"
                >
                  <img width="130" src="/img/android.png" alt="" />
                </a>
              </div>

              <el-button
                class="login100-form-btn"
                type="success"
                @click="Signup"
                >Sign up now</el-button
              >
            </div>
          </div>
        </div>

        <el-form
          :model="loginForm"
          status-icon
          :rules="loginRules"
          id="loginForm"
          ref="loginForm"
          :class="!showForgot ? 'showForm' : 'hideForm'"
        >
          <div class="login-text-area for-mobile">
            <p class="text1">AB PLUS</p>
            <p class="text2">Your own account for</p>
            <p class="text3">LIFE ABROAD</p>
            <p class="text4">LIFESTYLE NEEDS</p>
            <p class="text5">Sign up for free on your phone</p>
            <div class="grid-qr">
              <div>
                <img width="180" src="/img/ab-qrcode.png" alt="" />
              </div>
              <div style="vertical-align: top; padding: 10px">
                <a
                  class="ls-layer-link"
                  href="https://itunes.apple.com/us/app/abfx/id1352795706?mt=8"
                  target="_blank"
                >
                  <img width="130" src="/img/apple.png" alt="" />
                </a>
                <br />
                <a
                  class="ls-layer-link"
                  href="https://play.google.com/store/apps/details?id=com.ab_money"
                  target="_blank"
                >
                  <img width="130" src="/img/android.png" alt="" />
                </a>
              </div>

              <el-button
                class="login100-form-btn"
                type="success"
                @click="Signup"
                >Sign up now</el-button
              >
              <p class="text6">Or login to your account</p>
            </div>
          </div>
          <span class="login100-form-title"> Online Banking Login </span>
          <el-form-item prop="username">
            <el-input
              prefix-icon="el-icon-user"
              type="text"
              v-model="loginForm.username"
              placeholder="Username"
              autocomplete="off"
              @keyup.enter.native="$refs.inputpassword.focus()"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              prefix-icon="el-icon-lock"
              type="password"
              v-model="loginForm.password"
              placeholder="Password"
              autocomplete="off"
              show-password
              ref="inputpassword"
              @keyup.enter.native="Login"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <div class="container-login100-form-btn">
              <el-button
                :loading="loading"
                :disabled="loading"
                class="login100-form-btn"
                type="primary"
                @click="Login"
                >Login</el-button
              >
            </div>
            <div class="text-center p-t-12">
              <span class="txt1"> </span>
              <el-button
                class="link-forget"
                type="text"
                @click="changeForm(true)"
                >Forgot your Password?</el-button
              >
            </div>
          </el-form-item>
        </el-form>

        <el-form
          :model="forgotForm"
          status-icon
          :rules="forgotRules"
          id="forgotForm"
          ref="forgotForm"
          :class="showForgot ? 'showForm' : 'hideForm'"
        >
          <span class="login100-form-title"> Forgot your password? </span>

          <el-form-item class="forget-checkbox">
            <el-checkbox v-model="forgotForm.forgot_user">Username</el-checkbox>
            <el-checkbox v-model="forgotForm.forgot_password"
              >Password</el-checkbox
            >
          </el-form-item>

          <el-form-item
            prop="email"
            label="Please, enter your email address"
            class="mb-2"
          >
            <el-input
              prefix-icon="el-icon-message"
              type="text"
              v-model="forgotForm.email"
              placeholder="Email"
              autocomplete="off"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <div class="container-login100-form-btn">
              <el-button
                :loading="loadingForgot"
                class="login100-form-btn"
                type="primary"
                @click="Forgot"
                >Continue</el-button
              >
            </div>
            <div class="text-center p-t-12">
              <el-button
                class="link-forget"
                type="text"
                @click="changeForm(false)"
              >
                Go back to the sign in page</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <agreement ref="agreement" v-on:submited="Agreemented" />
  </div>
</template>

<script>
import Agreement from "@/components/Agreement.vue";
import { getToken, forgotPassword, login } from "@/api/user";
import { isMobile } from "@/utils/helpers";
import CustomerApi from "@/api/customer";
export default {
  components: {
    Agreement,
  },
  computed: {
    isMobile() {
      return isMobile();
    },
  },
  data() {
    return {
      loading: false,
      loadingForgot: false,
      showForgot: false,
      loginForm: {
        username: "",
        password: "",
        token: "",
      },
      forgotForm: {
        email: "",
        forgot_user: false,
        forgot_password: false,
      },
      loginRules: {
        username: [
          {
            required: true,
            message: "Please enter your username",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "Please enter your password",
            trigger: "blur",
          },
        ],
      },
      forgotRules: {
        email: [
          {
            required: true,
            message: "Please enter your email",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Please enter correct email format",
            trigger: "blur",
          },
        ],
      },
      redirect: undefined,
      otherQuery: {},
      userType: "",
      loginToken: "",
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        const query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true,
    },
  },
  methods: {
    getServerToken() {
      getToken().then((res) => {
        if (res && res["result"]) {
          this.loginForm.token = res["data"]["token"];
        }
      });
    },
    Login() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          login(this.loginForm).then(({ result, data, message }) => {
            this.loading = false;
            if (result && data.scapending) {
              this.socketSubscription(data.user.uid);
            } else if (result && !data.scapending) {
              this.storeUser(data);
            } else {
              this.$swal("Message", message, "error").then(() => {
                this.getServerToken();
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getAccount(token, cb) {
      CustomerApi.account().then(({ result, data }) => {
        if (result) {
          data["token"] = token;
          this.$store.dispatch("user/login", data).then(() => {
            cb();
          });
        }
      });
    },
    storeUser(data) {
      this.userType = data.userType;
      this.loginToken = data.token;
      if (data && data.syncagreement) {
        this.$store.dispatch("user/login", data).then(() => {
          if (data.kycVerified) {
            this.getAccount(data.token, () => {
              if (data.userType == "plus") {
                this.$router.push({ path: "/dashboard" });
              } else {
                this.$router.push({ path: "/wallet/dashboard" });
              }
            });
          } else {
            this.$router.push({ path: "/know-your-customer" });
          }
        });
      } else {
        this.$store.dispatch("user/login", data).then(() => {
          if (data.kycVerified) {
           this.$refs.agreement.open();
          } else {
            
              this.$router.push({ path: "/know-your-customer" });
          }
        });
      }
    },
    Forgot() {
      this.$refs.forgotForm.validate((valid) => {
        if (valid) {
          if (
            !this.forgotForm.forgot_user &&
            !this.forgotForm.forgot_password
          ) {
            this.$swal(
              "Message",
              "Please select Username or Password",
              "error"
            );
            return false;
          }
          this.loadingForgot = true;
          forgotPassword(this.forgotForm).then(({ result, message }) => {
            this.loadingForgot = false;
            if (result) {
              this.$router.push({ path: "/lost-password" });
            } else {
              this.$swal("Message", message, "error");
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== "redirect") {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    },
    changeForm(showForgot) {
      this.showForgot = showForgot;
      if (this.$refs.forgotForm) this.$refs.forgotForm.resetFields();
      if (this.$refs.loginForm) this.$refs.loginForm.resetFields();
    },
    Agreemented() {
      this.$store.dispatch("user/setAgreement", true).then(() => {
        this.getAccount(this.loginToken, ()=>{
          if (this.userType == "plus") {
          this.$router.push({ path: "/dashboard" });
        } else {
          this.$router.push({ path: "/wallet/dashboard" });
        }
        });
        
      });
    },
    socketSubscription(plusuid) {
      this.$swalTimer(
        "Login Pending",
        "Please confirm login from your mobile device",
        "warning"
      );
      this.$swal.showLoading();
      this.$socket.emit('join room', plusuid);
      this.sockets.subscribe("login:" + plusuid, (data) => {
        this.$swal.close();
        this.$socket.disconnect();

        if (data.result) {
          this.$swal.fire({
            icon: "success",
            title: "Login Success",
            showConfirmButton: false,
          });
          setTimeout(() => {
            this.$swal.close();
            this.storeUser(data.data);
          }, 2000);
        } else {
          this.$swal("Message", data.message, "error");
          this.getServerToken();
        }
      });
    },
    Signup() {
      window.location = "https://ab-money.co.uk/ab-money-register-form";
    },
  },
  mounted() {
    this.$socket.connect();
  },
  created() {
    this.getServerToken();
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/login.scss";

.login-container {
  .showForm {
    display: block;
  }

  .hideForm {
    display: none;
  }

  .forget-checkbox {
    &.el-form-item {
      margin-bottom: 0.5rem !important;
    }

    .el-form-item__content {
      line-height: 20px !important;
    }

    label {
      color: #fff;
    }
  }
}
</style>
